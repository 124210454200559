import React, { Component } from "react";
import { getUser, getUserRotations } from "../../api";
import moment from "moment-timezone";
import EditProfile from "./EditProfile";
import MonthlyRotations from "./MonthlyRotations";
import TimezoneSelector from "../TimezoneSelector";
import { Timezones } from "../Timezones";
import "./Profile.css";
import Loader from "../Loader";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Profile extends Component {
  state = {
    user: {},
    rotations: {},
    timeZone: "Etc/UTC",
    editData: undefined,
    showLoader: false,
    currentMonth: moment().format("YYYY-MM"),
    previousMonth: moment().subtract(1, "months").format("YYYY-MM"),
    nextMonth: moment().add(1, "months").format("YYYY-MM"),
  };

  labels = {
    ID: "Pega ID",
    BacklogID: "Backlog ID",
    FullName: "Full Name",
    FirstName: "First Name",
    DisplayName: "Display Name",
    LastName: "Last Name",
    JobTitle: "Job Title",
    Email: "Email",
    Location: "Location",
    PhonePrimary: "Primary Phone",
    PhoneFallback: "Fallback Phone",
    ManagerID: "Manager ID",
    Timezone: "Preview Timezone",
  };

  componentDidMount() {
    this.toggleLoader(true);
    let userId = this.props.match.params.userId;
    let startDate = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
    let endDate = moment().add(1, "months").endOf("month").format("YYYY-MM-DD");

    getUser(userId).then((res) => {
      this.setState({ user: res[userId] });
    });
    getUserRotations(userId, startDate, endDate).then((res) => {
      this.setState({ rotations: res });
      this.toggleLoader(false);
    });
  }

  getUserTimezoneName = (user) => {
    let userTimezone = Timezones.filter((timezone) => {
      return timezone.id === user.timezone;
    });

    return userTimezone[0] ? userTimezone[0].name : "Etc/UTC";
  };

  onClickEdit = (value) => {
    this.setState({ editData: value });
  };

  dissmissModal = () => {
    this.setState({ editData: undefined });
  };

  onSuccessEdit = (message) => {
    getUser(this.state.user.ID).then((res) => this.setState({ user: res[this.state.user.ID] }));
    toast.success("Success!", { position: toast.POSITION.TOP_RIGHT });
  };

  onFailureEdit = (message) => {
    this.setState({});
    toast.error("Failed to Save!", { position: toast.POSITION.TOP_RIGHT });
  };

  toggleLoader = (toggle) => {
    this.setState({ showLoader: toggle });
  };

  editingOwnProfile = () => {
    if (!this.props.ActiveUser.id || !this.state.user.ID) {
      return false;
    }

    return this.props.ActiveUser.id === this.state.user.ID;
  };

  canEdit = (property) => {
    const { ActiveUser, Roles } = this.props;

    if (!ActiveUser.role) return false;

    switch (property) {
      case "BacklogID":
        return false; // (Roles.Administrator.includes(ActiveUser.role));
      case "DisplayName":
        return false; // (Roles.Administrator.includes(ActiveUser.role) ) //|| this.editingOwnProfile());
      case "phone":
        return (Roles.BacklogRepresentative.includes(ActiveUser.role));
      case "Timezone":
        return false; // (Roles.Administrator.includes(ActiveUser.role)) // || this.editingOwnProfile());
    }
  };

  canPreview = (property) => {
    const { ActiveUser, Roles } = this.props;

    if (!ActiveUser.role) return false;

    switch (property) {
      case "phone":
        return (
          Roles.BacklogRepresentative.includes(ActiveUser.role) ||
          Roles.EscalationLeadership.includes(ActiveUser.role) ||
          this.editingOwnProfile()
        );
      case "Timezone":
        return Roles.BacklogRepresentative.includes(ActiveUser.role) || this.editingOwnProfile();
    }
  };

  splitPhone = (valueData) => {
    if (valueData.endsWith("U")) {
      let removedU = valueData.slice(0, -2);
      if (removedU.endsWith("-")) {
        removedU = removedU.slice(0, -1);
      } else {
        const parts = removedU.split("-");
        const tempcountryCode = parts[0];
        const tempsetPhoneNumber = parts[1];
        const tempExtension = "extn-" + parts[2];
        removedU = tempcountryCode + "-" + tempsetPhoneNumber + " " + tempExtension;
      }
      return "+" + removedU;
    }
    return valueData;
  };

  getRotationsByMonth(direction) {
    const currentYear = moment().format("YYYY");
    const currentMonthMMM = new Date().toLocaleString("default", { month: "short" });


    // Determine the month and year to filter based on the direction
    let targetMonthMMM, targetYear;
    if (direction === "previous") {
      targetMonthMMM = new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleString("default", {
        month: "short",
      });
      targetYear = currentMonthMMM === "Jan" ? (parseInt(currentYear) - 1).toString() : currentYear;
    } else if (direction === "next") {
      targetMonthMMM = new Date(new Date().setMonth(new Date().getMonth() + 1)).toLocaleString("default", {
        month: "short",
      });
      targetYear = currentMonthMMM === "Dec" ? (parseInt(currentYear) + 1).toString() : currentYear;
    }  else if (direction === "current") {
      targetMonthMMM = new Date(new Date().setMonth(new Date().getMonth())).toLocaleString("default", {
        month: "short",
      });
      targetYear = currentYear;
    }


    const filteredData = Object.values(this.state.rotations).filter((item) => {
      const rotationDate = item.RotationDate;
      const rotationYear = rotationDate.slice(0, 4);
      const rotationMonth = rotationDate.slice(4, 6);
      const rotationMonthMMM = moment(rotationMonth, "MM").format("MMM");
      console.log(direction,  rotationYear , String(targetYear), rotationMonthMMM, String(targetMonthMMM))
      return rotationYear === String(targetYear) && rotationMonthMMM === String(targetMonthMMM);
    });


    return filteredData;
  }
  render() {
    const { user, editData } = this.state;

    return (
      <div className="Profile">
        {Object.keys(this.state.user).length == 0 || this.state.showLoader ? (
          <Loader showLoader={this.state.showLoader} />
        ) : (
          <>
            <div className="row">
              <div className="col-sm-6">
                <h1 className="section-header">User Profile</h1>
              </div>
              <div className="col-sm-6">
                <TimezoneSelector
                  setTimezone={(value) => this.setState({ timeZone: value })}
                  getTimezone={() => this.state.timeZone}
                  ActiveUser={this.props.ActiveUser}
                />
              </div>
            </div>
            <ToastContainer hideProgressBar={true} />
            <div className="row">
              <div className="col-sm-6">
                {user && (
                  <div className="userProfile">
                    <ul className="list-group list-group-flush text-left">
                      <li className="list-group-item">
                        <b>{this.labels["ID"]}:</b> {user.ID}
                      </li>
                      <li className="list-group-item">
                        <b>{this.labels["BacklogID"]}:</b>{" "}
                        {user.BacklogID != undefined && user.BacklogID.map != undefined
                          ? user.BacklogID.map((item) => item + ",")
                          : user.BacklogID}
                        {this.canEdit("backlogId") && (
                          <button
                            className="btn btn-link buttonEdit"
                            value="BacklogId"
                            onClick={(e) => this.onClickEdit("BacklogID")}
                          >
                            Edit
                          </button>
                        )}
                      </li>
                      <li className="list-group-item">
                        <b>{this.labels["FullName"]}:</b> {user.FirstName} {user.LastName}
                      </li>
                      <li className="list-group-item">
                        <b>{this.labels["DisplayName"]}:</b> {user.DisplayName}
                        {this.canEdit("DisplayName") && (
                          <button
                            className="btn btn-link buttonEdit"
                            value="DisplayName"
                            onClick={(e) => this.onClickEdit("DisplayName")}
                          >
                            Edit
                          </button>
                        )}
                      </li>
                      <li className="list-group-item">
                        <b>{this.labels["JobTitle"]}:</b> {user.JobTitle}
                      </li>
                      <li className="list-group-item">
                        <b>{this.labels["Email"]}:</b> {user.Email}
                      </li>
                      <li className="list-group-item">
                        <b>{this.labels["Location"]}:</b> {user.Location}
                      </li>
                      {this.canPreview("phone") && (
                        <li className="list-group-item">
                          <b>{this.labels["PhonePrimary"]}:</b> {this.splitPhone(user.PhonePrimary)}
                          {!user.PhonePrimary && (
                            <span className="font-extra-bold text-orange left-10">No phone number provided!</span>
                          )}
                          {this.canEdit("phone") && (
                            <button
                              className="btn btn-link buttonEdit"
                              value="PhonePrimary"
                              onClick={(e) => this.onClickEdit("PhonePrimary")}
                            >
                              Edit
                            </button>
                          )}
                        </li>
                      )}
                      {this.canPreview("phone") && (
                        <li className="list-group-item">
                          <b>{this.labels["PhoneFallback"]}:</b> {this.splitPhone(user.PhoneFallback)}
                          {this.canEdit("phone") && (
                            <button
                              className="btn btn-link buttonEdit"
                              value="PhoneFallback"
                              onClick={(e) => this.onClickEdit("PhoneFallback")}
                            >
                              Edit
                            </button>
                          )}
                        </li>
                      )}
                      <li className="list-group-item">
                        <b>{this.labels["ManagerID"]}:</b> {user.ManagerID}
                      </li>
                      {this.canPreview("Timezone") && (
                        <li className="list-group-item">
                          <b>{this.labels["Timezone"]}:</b>
                          {this.getUserTimezoneName(user)}
                          {this.canEdit("Timezone") && (
                            <button
                              className="btn btn-link buttonEdit"
                              value="Timezone"
                              onClick={(e) => this.onClickEdit("Timezone")}
                            >
                              Edit
                            </button>
                          )}
                        </li>
                      )}
                    </ul>
                  </div>
                )}

                {editData && (
                  <EditProfile
                    user={user}
                    editData={editData}
                    labels={this.labels}
                    toggleLoader={this.toggleLoader}
                    dissmissModal={this.dissmissModal}
                    onSuccessEdit={this.onSuccessEdit}
                    onFailureEdit={this.onFailureEdit}
                  />
                )}
              </div>
              <div className="col-sm-6">
                <MonthlyRotations
                  month={this.state.previousMonth}
                  rotations={this.getRotationsByMonth("previous")}
                  timeZone={this.state.timeZone}
                />
                <MonthlyRotations
                  month={this.state.currentMonth}
                  rotations={this.getRotationsByMonth("current")}
                  timeZone={this.state.timeZone}
                />
                <MonthlyRotations
                  month={this.state.nextMonth}
                  rotations={this.getRotationsByMonth("next")}
                  timeZone={this.state.timeZone}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Profile;